import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-science",
          title: "Radiogenomics",
          desc:
            "Predicting genetic alterations in tumors through standard imaging",
        },
        {
          icon: "pe-7s-photo-gallery",
          title: "Tumor Segmentation",
          desc:
            "Segment Tumors and provided volumetric measurements in a reportable and viewable format ",
        },
        {
          icon: "pe-7s-note2",
          title: "Tumor Classification",
          desc:
            "Determine tumor classification through the use of standard imaging",
        },
      ],
      services2: [
        {
          icon: "pe-7s-graph1",
          title: "Patient Outcomes",
          desc:
            "Monitor patients and their outcomes through imaging and other critical biomarkers",
        },
        {
          icon: "pe-7s-vector",
          title: "Federated Learning",
          desc:
            "Allows for decentralized learning on medical data with having to move it around. Reducing security risk",
        },
        {
          icon: "pe-7s-unlock",
          title: "Homomorphic encryption",
          desc:
            "Training on encrypted data to keeping patient data safe at all times",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="services">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Services"
              desc="Grai Med’s mission is to apply AI to imaging and genetic data for clinicians, providing them better diagnostic, prognostic, and treatment results."
            />

            {/*<Row className="mt-4">*/}
            {/*  /!* services box *!/*/}
            {/*  <ServiceBox services={this.state.services1} />*/}
            {/*</Row>*/}

            {/*<Row className="mt-4">*/}
            {/*  /!* service box *!/*/}
            {/*  <ServiceBox services={this.state.services2} />*/}
            {/*</Row>*/}

            <Row className="mt-3">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-3">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
