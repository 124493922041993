import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TeamBox from "./TeamBox";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          image: "assets/images/team/ab.png",
          title: "Amanda Bridenstine",
          desc: "Chief Executve Officer & President",
        },
        {
          image: "assets/images/team/jm.png",
          title: "Jonathon Moore",
          desc: "Chief Technology Officer",
        },
        {
          image: "assets/images/team/mt.png",
          title: "Michael Taras",
          desc: "Vice President - Data Science",
        },
        // {
        //   image: "assets/images/team/bm.png",
        //   title: "Brooke Moore",
        //   desc: "Director - Creative Design & Marketing",
        // }
        // {
        //   image: "assets/images/team/img-4.jpg",
        //   title: "Joshua Stemple",
        //   desc: "MANAGER",
        // },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-4" id="team">
          <Container>
            {/* section title */}
            {/*<SectionTitle*/}
            {/*  title="Meet the Team"*/}
            {/*  desc="It is a long established fact that create category leading brand experiences a reader will be distracted by the readable content of a page when looking at its layout."*/}
            {/*/>*/}
            <SectionTitle
              title="Meet the Team"
            />

            <Row className="mt-4 justify-content-center">
              {/* team box */}
              <TeamBox teams={this.state.teams} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
