import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links: [
      {
        title: "",
        child: [
          // { link: "#", title: "Home" },
          // { link: "#", title: "About us" },
        ],
      },
      {
        title: "",
        child: [
          // { link: "#", title: "Terms & Condition" },
          // { link: "#", title: "Contact us" },
          // { link: "#", title: "Jobs" },
          // { link: "#", title: "Bookmarks" },
        ],
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.links.map((fLink, key) => (
                <Col key={key} lg={3} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}

              {/* Terms and Conditions */}
              <Col lg={12} className="mt-4">
                <h4>Terms and Conditions</h4>
                <div className="text-muted mt-4">
                  <p>
                  By accessing and using the Grai-Med website (previously known as RecurrentHealth), you agree to be bound by our Terms and Conditions. Please read these terms carefully before using the website. All intellectual property rights, including trademarks and copyrighted content, belong to Grai-Med. You are prohibited from using, modifying, reproducing, or distributing any part of the website's content without prior written permission from Grai-Med.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
