import React, { Component } from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

class PartnershipBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.partners.map((partner, key) => (
          <Col key={key} lg={4} sm={7}>
            <div className="partner-box text-center hover-effect">
              <div className="partner-wrapper">
                <div className="partner-member">
                  <Link to={{ pathname: partner.url }} target="_blank">
                    <img
                      alt={partner.title}
                      src={partner.image}
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
              </div>
              {/*<h4 className="team-name">{partner.title}</h4>*/}
              {/*<p className="text-uppercase team-designation">{team.desc}</p>*/}
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default PartnershipBox;
