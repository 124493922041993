import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import PartnershipBox from "./PartnershipBox";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: [
        {
          image: "assets/images/partnerships/ach-logo.png",
          title: "Akron Childern's Hospital",
          url: "https://www.akronchildrens.org/"
        },
        {
          image: "assets/images/partnerships/projectmedtech-logo2.png",
          title: "Project MedTech",
          url: "https://www.projectmedtech.com/"
        },
        {
          image: "assets/images/partnerships/bounce-logo.png",
          title: "Bounce Innovation Hub",
          url: "https://bouncehub.org/"
        }
        // {
        //   image: "assets/images/team/img-4.jpg",
        //   title: "Joshua Stemple",
        //   desc: "MANAGER",
        // },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-4" id="partnerships">
          <Container>
            {/* section title */}
            {/*<SectionTitle*/}
            {/*  title="Meet the Team"*/}
            {/*  desc="It is a long established fact that create category leading brand experiences a reader will be distracted by the readable content of a page when looking at its layout."*/}
            {/*/>*/}
            <SectionTitle
              title="Partnerships"
            />

            <Row className="mt-4 justify-content-center align-items-center">
              {/* team box */}
              <PartnershipBox partners={this.state.partners} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
