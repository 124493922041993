import React from "react";
import { Container, Row, Col } from "reactstrap";

const Contact = () => {
  return (
    <section className="contact-social bg-light">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h2 className="section-title">Contact Us</h2>
            <p className="section-subtitle">Schedule a demo today!</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <div className="contact-details">
              <p className="contact-title">Please contact us at:</p>
              <a href="mailto:information@grai-med.com" className="contact-email">information@grai-med.com</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;

